import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > img {
    width: 100%;
    z-index: -1;
    height: 400px;
    object-fit: cover;
  }
  .title-box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 80%;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-align: center;
    h2 {
      font-size: 1.2rem;
      color: #ffffff;
      margin-bottom: 1.5rem;
    }
    h1 {
      font-size: 1.4rem;
      color: #ffffff;
      font-weight: bold;
    }
  }
`;

export default function Banner({ banner }) {
  return (
    <Wrapper>
      <img src={banner} alt=" " />
      <div className="title-box">
        <h2>中国教育发展战略学会教师发展专业委员会</h2>
        <h1>“十四五”幼儿园重点课题</h1>
        <h1>信息化背景下的家庭教育研究</h1>
      </div>
    </Wrapper>
  );
}
