import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { HOME_DATA } from './__data';

const Wrapper = styled.section`
  position: relative;
  font-family: Microsoft YaHei;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    height: 1.7rem;
    font-size: 1.75rem;
    color: #e50113;
    margin-top: 6.3rem;
    margin-bottom: 1.75rem;
  }
  h4 {
    width: 18rem;
    font-size: 0.8rem;
    color: #424242;
    line-height: 1.5rem;
    text-align: justify;
  }
  .about-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 5.95rem;
    margin-bottom: 4.05rem;
    .about-item {
      flex: 0 1 45%;
      position: relative;
      height: 20rem;
      .about-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
        img {
          width: 5.84rem;
          height: 4.65rem;
          display: block;
          margin-bottom: 0.35rem;
        }
        .title {
          height: 1.1rem;
          font-size: 1.11rem;
          color: #424242;
        }
      }
      .desc {
        font-size: 0.7rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #636363;
        line-height: 1.2rem;
        margin: 0 auto;
        text-align: justify;
      }
      .more {
        width: 80%;
        margin-left: 10%;
        height: 1.87rem;
        background: #e50113;
        font-size: 0.9rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 1.06rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 2.05rem;
      }
    }
  }
`;

export default function About() {
  return (
    <Wrapper>
      <h1>关于课题</h1>
      <h4>
        为贯彻落实教育部《关于加强家庭教育工作的指导意见》要求，中国教育发展战略学会教师发展专业委员会拟在
        “十四五”期间（2021—2025年），计划在全国部分幼儿园开展“信息化背景下的家庭教育研究”。
      </h4>
      <div className="about-box">
        {HOME_DATA.map(({ title, desc, icon, url }) => (
          <div key={title} className="about-item">
            <div className="about-header">
              <img src={icon} alt="" />
              <div>{title}</div>
            </div>
            <div className="desc">{desc}</div>
            <Link to={url}>
              <div className="more">了解更多</div>
            </Link>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}
